<template>
  <div class="container no-access">
    <Branding />
    <h2>{{ contents.no_access }}</h2>
  </div>
</template>

<script>
import contents from '../assets/contents.json'
import Branding from '@/components/Branding'

export default {
  name: 'NoAccess',
  components: {
    Branding,
  },
  setup: () => ({ contents }),
}
</script>
